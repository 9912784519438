<template>
  <b-container class="lp-sec__one p-0">
    <div class="lp-one__heading">
      <h5 class="text-header">{{ $t("landing_page.all_courses_heading") }}</h5>
      <Button
        variant="primary-lighter"
        className="text-uppercase lp-button__height"
        @click="showCourses"
        >{{ $t("landing_page.all_courses.button.label") }}</Button
      >
    </div>
    <!-- Desktop -->
    <b-row class="lp-one__md">
      <b-col
        md="4"
        lg="3"
        sm="12"
        class="lp-one__card mb-3"
        v-for="course in courses"
        :key="course.id"
      >
        <CourseCard
          :courseImage="
            course.image_url
              ? course.image_url
              : require('@/assets/images/illustration/course-illustration.svg')
          "
          :org="course.org_display_name"
          :courseTitle="course.title"
          :language="language"
          :pacing="course.pacing"
          :courseLanguage="course.language"
          :course="course"
          @click="onCard(course)"
        />
      </b-col>
    </b-row>

    <!-- in mobile case -->
    <b-row class="lp-one__xs slider">
      <b-col cols="3" v-for="course in courses" :key="course.colIndex">
        <CourseCard
          :courseImage="
            course.image_url
              ? course.image_url
              : require('@/assets/images/illustration/course-illustration.svg')
          "
          :org="course.org"
          :language="language"
          :courseTitle="course.title"
          :pacing="course.pacing"
          :courseLanguage="course.language"
          :course="course"
          @click="onCard(course)"
        />
      </b-col>
    </b-row>

    <div class="lp-one__xs--button justify-content-center align-items-center">
      <Button
        variant="primary-lighter"
        className="text-uppercase lp-button__height"
        @click="showCourses"
        >{{ $t("landing_page.all_courses.button.label") }}</Button
      >
    </div>
  </b-container>
</template>
<script>
import { tns } from "tiny-slider/src/tiny-slider";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["language"])
  },
  data() {
    return {
      isClLoading: true,
      isDlLoading: true,
      sliderItems: 1,
      slider: null,
      courses: []
    };
  },
  mounted() {
    this.getCourses();
  },
  methods: {
    showCourses() {
      this.$router.push({ name: "SearchCourses" });
      document.getElementById("app").scrollIntoView();
    },
    onCard(c) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: c.id },
        query: { source: c.source }
      });
      document.getElementById("app").scrollIntoView();
    },
    getCourses() {
      this.$store.dispatch("getRandomCourses", 7).then(data => {
        this.courses = data;
        this.isDlLoading = false;
        setTimeout(() => {
          this.initiateSlider();
        }, 50);
      });
    },

    initiateSlider() {
      if (this.slider) {
        this.slider.destroy();
      }
      this.slider = tns({
        container: ".slider",
        items: this.sliderItems,
        slideBy: "page",
        autoplay: false,
        controls: false,
        loop: false,
        nav: false
      });
      this.getSliderInfo();
    },
    slideTo(action) {
      this.slider.goTo(action);
      this.getSliderInfo();
    },
    getSliderInfo() {
      let slider_info;
      if (this.slider) {
        slider_info = this.slider.getInfo();
      }
      this.currentSlide = slider_info ? slider_info.index : 0;
    }
  }
};
</script>
<style lang="scss">
.lp-sec__one {
  background: #fff;
  margin-top: 128px;
  .lp-one__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text-header {
      @include heading;
      text-align: left;
      width: 600px;
    }
  }
  .lp-one__xs,
  .lp-one__xs--button {
    display: none;
    margin: 30px 0;
  }
  .lp-one__md {
    margin-top: 64px;
  }
  @media screen and (max-width: 500px) {
    .slider .tns-item {
      width: 10% !important;
    }
    .tns-ovh {
      text-align: initial !important;
      margin-left: -15px !important;
      padding-left: 15px;
    }
  }
}

// arabic alignment
[dir="rtl"] {
  .lp-sec__one {
    .lp-one__heading {
      .text-header {
        text-align: right;
      }
    }
  }
}
.lp-one__md {
  margin-top: $landing_page_common_margin_top;
}

@media (min-width: 500px) and (max-width: 768px) {
  .lp-one__card {
    width: 50%;
  }
  .lp-button__height {
    margin-bottom: 64px;
  }
  .lp-one__heading {
    margin-top: 64px;
  }
  .lp-sec__one {
    margin-top: 64px;
  }
  .lp-one__md {
    margin-top: 64px;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .lp-sec__one {
    margin-top: 64px;
  }
  .lp-one__md {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 481px) {
  .lp-sec__one {
    padding: 0px 16px;
    margin-top: 64px;
    .lp-one__heading {
      .text-header {
        text-align: center !important;
        font-size: 48px;
      }
      .lp-button__height {
        display: none;
      }
    }
    .lp-one__xs {
      display: block;
    }

    .lp-one__xs--button {
      display: flex;
    }
    .lp-one__md {
      display: none;
    }
  }
}

@media screen and (min-width: 482px) and (max-width: 990px) {
  .lp-sec__one {
    padding: 0px 40px !important;
    .lp-one__heading {
      justify-content: center;
      .text-header {
        text-align: center;
      }
      .lp-button__height {
        display: none;
      }
    }
    .lp-one__xs--button {
      display: flex;
    }
  }
  [dir="rtl"] {
    .lp-sec__one {
      .lp-one__heading {
        .text-header {
          text-align: center;
        }
      }
    }
  }
}
</style>
